<script setup>
import {
  getLiveRecommendListApi,
  getPlaybackListApi,
  getIndexPlacementListApi,
  getIndexPlacementBannerApi
} from "../../api/live.js";
import { getVideoListApi } from "../../api/video.js";
import { useRouter } from "vue-router";
const router = useRouter();
const liveTabList = ref([]);

const videoList = ref([
  {
    label: "直播推荐",
    eng: "Live Recommendations",
    videoType: "1",
    list: [],
  },
  {
    label: "视频推荐",
    eng: "Video Recommendation",
    videoType: "2",
    list: [],
  },
]);

// 获取顶部banner列表数据
const placementId = ref(null);
const placementList = ref([]);
const getIndexPlacementList = () => {
  getIndexPlacementListApi().then((res) => {
    if (res.code === 200) {
      let data = res.data;
      data = data.filter(item => item.hidden === '0');
      placementId.value = data[0]?.id;
      liveTabList.value = data;
      getIndexPlacementBanner();
    }
  });
};

const getIndexPlacementBanner = () => {
  const params = {
    pageSize: 3,
    pageNum: 1,
    id: placementId.value
  };
  getIndexPlacementBannerApi(params).then(res => {
    if (res.code === 200) {
      let data = res.rows;
      placementList.value = data;
    }
  })
};

// 切换banner显示类型
const handleChange = (id) => {
  placementId.value = id;
  getIndexPlacementBanner();
};

// 获取直播推荐列表
const getLiveRecommendList = () => {
  const params = {
    pageNum: 1,
    pageSize: 4,
  };
  getLiveRecommendListApi(params).then((res) => {
    let data = res.data;
    if (data && data.length > 0) {
      data = res.data.slice(0, 4);
      data.map(v => {
        v.livePictureAddress = v.livePhotographAddress;
      })
    }
    videoList.value[0].list = data;
  });
};

// 获取视频推荐列表
const recommendVideoList = () => {
  const params = {
    pageNum: 1,
    pageSize: 4,
    recommended: 1,
  };
  getVideoListApi(params).then((res) => {
    let data = res.rows;
    if (data && data.length > 0) {
      res.rows.map((v) => {
        v.coverImageUrl = JSON.parse(v.coverImageUrl)[0];
      });
      videoList.value[1].list = data;
    }
  });
};

// 获取直播回放列表数据
const playbackList = ref([]);
const getPlaybackList = () => {
  const params = {
    pageSize: 4,
    pageNum: 1
  };
  getPlaybackListApi(params).then((res) => {
    let data = res.rows;
    if (data && data.length > 0) {
      data.map(v => {
        v.livePictureAddress = v.livePhotographAddress;
      })
      data = data.slice(0, 4);
    }
    playbackList.value = data;
  });
};

// 进入直播间
const openEnterDetail = (item) => {
  if (item.liveState === "0") {
    router.push("/liveBroadcastPreview?id=" + item.id);
  } else if (item.liveState === "1") {
    router.push("/liveBroadcastDetail?id=" + item.id);
  } else if (item.liveState === "2") {
    router.push("/livePause?id=" + item.id);
  }
};

// 查看更多
const handleMore = (item) => {
  const type = item.videoType;
  if (type === "1") {
    router.push("/liveBroadcast");
  } else {
    router.push("/videoCenter");
  }
};
// 跳转详情
const openDetail = (type, item) => {
  if (type === "1") {
    if (item.liveState === "1") {
      router.push("/liveBroadcastDetail?id=" + item.id);
    } else {
      router.push("/liveBroadcastPreview?id=" + item.id);
    }
  } else {
    router.push("/videoCenter/videoDetail?id=" + item.id);
  }
};
// 跳转回放列表
const openVideocast = () => {
  router.push("/playback");
};

// 跳转回放详情
const openPlaybackDetail = (item) => {
  router.push("/playbackDetail?id=" + item.id);
};

onMounted(() => {
  getIndexPlacementList();
  getLiveRecommendList();
  recommendVideoList();
  getPlaybackList();
});
</script>

<template>
  <div class="flexColumn" style="font-size: 0;">
    <!-- banner -->
    <div class="bg_container_2">
      <img src="@/assets/index/index_bg_3.png" class="bg_3" />
      <div class="main_box">
        <router-view v-slot="{ Component, route }">
          <keep-alive v-if="route.meta">
            <component :is="Component"></component>
          </keep-alive>
        </router-view>
        <div class="live_box">
          <div class="live_box_left">
            <template v-if="placementList && placementList.length > 0">
              <el-carousel :interval="5000" arrow="hover" height="360" autoplay>
                <el-carousel-item
                  v-for="(item, index) in placementList"
                  :key="index"
                >
                  <img :src="item.livePictureAddress" class="banner_img" />
                  <!-- <h3 text="2xl" justify="center">{{ item }}</h3> -->
                  <div
                    class="live_box_left_status"
                    v-if="item.liveState === '1'"
                  >
                    <img src="@/assets/gif/live.gif" />直播中
                  </div>
                  <div
                    class="live_box_left_enter"
                    @click="openEnterDetail(item)"
                    v-if="
                      item.liveState &&
                      (item.liveState === '0' ||
                        item.liveState === '1' ||
                        item.liveState === '2')
                    "
                  >
                    {{ item.liveState === '1' ? '进入直播间' : '直播预约' }}
                  </div>
                </el-carousel-item>
              </el-carousel>
            </template>
            <template v-else>
              <div class="no_source"></div>
              <div class="no_txt">暂无内容</div>
            </template>
          </div>
          <div class="live_box_right">
            <div
              class="live_box_right_item"
              :class="placementId === item.id ? 'active' : ''"
              v-for="(item, index) in liveTabList"
              :key="index"
              @click="handleChange(item.id)"
            >
              {{ item.classificationName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- recommend -->
    <div class="live_box_main">
      <div
        class="live_box_section"
        v-for="(item, index) in videoList"
        :key="index"
      >
        <div class="live_box_section_title">
          <div class="cube"></div>
          <div>
            <span class="label">{{ item.label }}</span>
            <span class="eng">{{ item.eng }}</span>
          </div>
        </div>
        <div class="live_box_section_content">
          <div
            class="live_video_item"
            v-for="(itm, idx) in item.list"
            :key="idx"
            @click="openDetail(item.videoType, itm)"
          >
            <template v-if="item.videoType === '1' || item.videoType === '2' && itm.hidden === '0'">
              <div class="live_video_item_img" :class="item.videoType === '1' && itm.liveState === '1' ? 'live-active' : ''">
                <img v-if="item.videoType === '1'" :src="itm?.livePictureAddress" />
                <img v-if="item.videoType === '2'" :src="itm?.coverImageUrl?.url" />
                <div class="live_video_item_status" v-if="itm.liveState === '1'">
                  <img src="@/assets/gif/live.gif" />
                  直播中
                </div>
                <div
                  class="views"
                  v-if="item.videoType === '1' && itm.liveState === '1' || item.videoType === '2'"
                >
                  <div class="flexRow aICenter">
                    <img src="@/assets/index/icon_eye.png" alt="" />
                    {{ itm.views || 0 }}
                  </div>
                  <div class="flexRow aICenter" style="margin-left: 20px;" v-if="item.videoType === '2'">
                    <img src="@/assets/icon/icon_like.png" alt="" />
                    {{ itm.likes || 0 }}
                  </div>
                </div>
              </div>
              <div class="live_video_item_content">
                <div
                  class="live_video_item_content_title"
                  :class="item.videoType === '1' ? 'text_line_1' : 'text_line_2'"
                >
                  {{ itm.title }}
                </div>
                <template v-if="item.videoType === '1'">
                  <div
                    class="live_video_item_content_name"
                    v-if="itm.liveState === '1'"
                  >
                    主播: {{ itm.anchorIdStr || "-" }}
                  </div>
                  <div class="live_video_item_content_name" v-else>
                    时间: {{ itm.liveTimeStart }}
                  </div>
                </template>
              </div>
            </template>
          </div>
          <div v-if="!item.list?.length > 0" class="no_more">
            <div class="no_source"></div>
            <div class="no_txt">暂无内容</div>
          </div>
        </div>
        <div
          class="view_more"
          v-if="item.list && item.list.length > 0"
          @click="handleMore(item)"
        >
          查看更多
        </div>
      </div>
    </div>
    <!-- reply -->
    <div class="video_box_main">
      <div class="video_box_main_container">
        <div class="video_box_main_title flexColumn aICenter jCCenter">
          <div class="flexRow aICenter jCCenter">
            <div class="cube"></div>
            <div class="video_box_main_title_txt">录播回放</div>
            <div class="cube"></div>
          </div>
          <div class="video_box_main_title_eng">Videocast</div>
        </div>
        <div
          class="more"
          v-if="playbackList && playbackList?.length > 4"
        >
          <div></div>
          <div @click="openVideocast" class="pointer">更多<img src="@/assets/index/icon_more.png" /></div>
        </div>
        <div class="video_box_main_list">
          <div
            class="video_box_main_list_item"
            v-for="(item, index) in playbackList"
            :key="index"
            @click="openPlaybackDetail(item)"
          >
            <img :src="item.livePictureAddress" />
            <div class="video_box_main_list_item_content">
              <div class="title text_line_1">{{ item.title }}</div>
              <div class="liver">主播: {{ item.anchorName || "-" }}</div>
            </div>
          </div>
        </div>
        <div v-if="!playbackList?.length" class="no_more">
          <el-empty description="暂无内容"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-carousel) {
  width: 100%;
}
:deep(.el-carousel__container) {
  height: 360px;
  // width: 970px;
  width: 100%;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
@keyframes noScale {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.bg_container_2 {
  position: relative;
  height: 490px;
  position: relative;
  .bg_3 {
    width: 100%;
    height: 490px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .main_box {
    width: 1300px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    .nav {
      width: 100%;
      height: 110px;
      background: #ffffff;
      box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 24px;
        color: #306ff0;
        line-height: 28px;
        border-right: 1px solid #d7d7d7;
        cursor: pointer;
        user-select: none;
        img {
          width: 60px;
          height: 60px;
          margin-right: 40px;
        }
      }
      .item:last-child {
        border: none;
      }
    }
    .live_box {
      margin-top: 90px;
      width: 100%;
      height: 360px;
      // background-color: #eee;
      display: flex;
      .live_box_left {
        flex: 4.2174;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
        .banner_img {
          width: 100%;
          height: 100%;
          background-color: #fff;
          object-fit: cover;
          object-position: center;
        }
        .banner_img:hover {
          animation: scale 0.2s linear forwards;
        }
        .banner_img:not(:hover) {
          animation: noScale 0.2s linear forwards;
        }

        .no_source {
          height: 300px;
          width: 300px;
          background-image: url(@/assets/index/no_source.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
        }

        .no_txt {
          font-size: 16px;
          font-weight: 500;
          color: #999;
        }

        .live_box_left_status {
          position: absolute;
          top: 12px;
          left: 26px;
          display: flex;
          align-items: center;
          // justify-content: center;
          font-weight: 400;
          font-size: 14px;
          color: #ff3950;
          background: #ffefef;
          width: 90px;
          height: 30px;
          border-radius: 15px;
          padding-left: 4px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
        .live_box_left_enter {
          cursor: pointer;
          position: absolute;
          bottom: 20px;
          right: 27px;
          width: 164px;
          height: 54px;
          background: rgba(31, 31, 31, 0.39);
          border-radius: 2px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          font-weight: 400;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
          text-align: center;
          line-height: 54px;
          border: 1px solid rgba(255, 255, 255, 0.5);
        }
      }
      .live_box_right {
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
        background: rgba(44, 60, 111, 0.8);
        .live_box_right_item {
          box-sizing: border-box;
          width: 100%;
          height: 72px;
          text-align: center;
          line-height: 72px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          font-weight: 400;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
          // background: rgba(44, 60, 111, 0.8);
          cursor: pointer;
        }
        // .live_box_right_item:last-child {
        //   border: none;
        // }
        .active {
          color: #fff;
          font-weight: 600;
        }
      }
      // .live_box_right::-webkit-scrollbar {
      //   width: 0;
      //   display: none;
      // }
    }
  }
}
.live_box_main {
  width: 1300px;
  margin: 0 auto;
  padding: 70px 0;
  display: flex;
  justify-content: space-between;
  .live_box_section {
    width: 636px;
    background: linear-gradient(180deg, #eff3fa 0%, #fdfeff 100%);
    box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
    border: 4px solid #ffffff;
    padding: 24px 24px 20px;
    .live_box_section_title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: 0;
      user-select: none;
      .label {
        font-weight: 600;
        font-size: 24px;
        color: #1f1f1f;
        line-height: 28px;
        margin-left: 10px;
      }
      .eng {
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        line-height: 19px;
        margin-left: 10px;
      }
    }
    .live_box_section_content {
      width: 100%;
      height: 586px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 五列网格，每列平均分布 */
      gap: 20px; /* 列之间的间距 */
      justify-content: space-between;
      position: relative;
      .live_video_item {
        width: 280px;
        height: 280px;
        cursor: pointer;
        background-color: #fff;
        padding: 4px;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
        .live_video_item_img {
          width: 100%;
          height: 188px;
          position: relative;
          box-sizing: border-box;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          .live_video_item_status {
            position: absolute;
            top: 7px;
            left: 4px;
            display: flex;
            align-items: center;
            // justify-content: center;
            font-weight: 400;
            font-size: 14px;
            color: #ff3950;
            background: #ffefef;
            width: 90px;
            height: 30px;
            border-radius: 15px;
            padding-left: 4px;
            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
          .views {
            position: absolute;
            bottom: 8px;
            left: 8px;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 16px;
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }
        .live-active {
          border: 2px solid #ff3950;
        }
        .live_video_item_content {
          width: 100%;
          padding: 20px 10px 0;
          box-sizing: border-box;
          .live_video_item_content_title {
            width: 100%;
            font-weight: 600;
            font-size: 18px;
            color: #1f1f1f;
            line-height: 21px;
          }
          .live_video_item_content_name {
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 16px;
            margin-top: 10px;
          }
        }
      }
      .live_video_item:not(:hover) {
        animation: noScale 0.2s linear forwards;
      }
      .live_video_item:hover {
        animation: scale 0.2s linear forwards;
      }
      .no_more {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .no_source {
          width: 250px;
          height: 250px;
          background-image: url(@/assets/index/no_source.png);
          background-size: 100% 100%;
          background-position: center;
          background-repeat: no-repeat;
        }
        .no_txt {
          font-size: 16px;
          font-weight: 500;
          color: #999;
        }
      }
    }
    .view_more {
      margin: 30px auto 0;
      width: 184px;
      height: 48px;
      user-select: none;
      background: linear-gradient(
        180deg,
        rgba(207, 221, 255, 0.3) 0%,
        rgba(93, 141, 254, 0.3) 100%
      );
      border-radius: 2px 2px 2px 2px;
      border: 2px solid #ffffff;
      font-weight: 400;
      font-size: 16px;
      color: #306ff0;
      text-align: center;
      line-height: 48px;
      cursor: pointer;
    }
  }
}
.video_box_main {
  width: 100%;
  // max-width: 1920px;
  max-height: 1100px;
  min-height: 400px;
  margin: 0 auto;
  background-image: url("@/assets/index/index_bg_4.png");
  background-size: 100% 100%;
  background-position: center;
  padding-top: 70px;
  .video_box_main_container {
    width: 1300px;
    margin: 0 auto 50px;
  }
  .video_box_main_title_txt {
    user-select: none;
    font-weight: 600;
    font-size: 24px;
    color: #1f1f1f;
    line-height: 28px;
    margin: 0 20px;
  }
  .video_box_main_title_eng {
    font-weight: 400;
    font-size: 16px;
    color: #999999;
    line-height: 19px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .more {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    // cursor: pointer;
    img {
      width: 6px;
      height: 9px;
      margin-left: 4px;
    }
  }
  .video_box_main_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 五列网格，每列平均分布 */
    gap: 24px 20px; /* 列之间的间距 */
    box-sizing: border-box;
    .video_box_main_list_item {
      width: 285px;
      background: #ffffff;
      box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
      border: 1px solid #f6f6f6;
      padding: 4px;
      box-sizing: border-box;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        height: 164px;
        object-fit: cover;
        object-position: center;
      }
      .video_box_main_list_item_content {
        box-sizing: border-box;
        margin-top: 20px;
        padding: 0 10px 20px;
        .title {
          font-weight: 600;
          font-size: 18px;
          color: #1f1f1f;
          line-height: 21px;
        }
        .liver {
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          line-height: 16px;
          margin-top: 10px;
        }
      }
    }
    .video_box_main_list_item:hover {
      animation: scale 0.2s linear forwards;
    }
    .video_box_main_list_item:not(:hover) {
      animation: noScale 0.2s linear forwards;
    }
  }
}
</style>
