<script setup>
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { onMounted, onUnmounted, nextTick } from "vue";
import Breadcrumb from "../../components/breadcrumb/index.vue";
import {
  getVideoDetailApi,
  getCommentListApi,
  addCommentsApi,
  videoLikeApi,
  getVideoListApi,
  tourVideolApi,
  queryLikeListApi
} from "../../api/video.js";
import Cookies from "js-cookie";
const router = useRouter();
const route = useRoute();

const videosId = ref("");
const scrollContainer = ref(null);
const isScrolledToTop = ref(false);
const commentContainer = ref(null);
const commentHeight = ref(0);
const bigVideo = ref(null);
const miniVideo = ref(null);
const message = ref("");
const commentCount = ref(0);
const reply = ref("");
const placeholder = ref("");
const showReply = ref(false);
const isLiked = ref(false);
const autofocus = ref(true);
const videoDetail = ref({});
const coverImageUrl = ref({});
const videoUrl = ref({});
const currentTime = ref(0);
const isPause = ref(false);
const userInfo = ref({});
const replyRef = shallowRef(null);
import ICON_LIKE_FULL from '@/assets/icon/icon_like_full.png';
import ICON_LIKE_GREY from '@/assets/icon/icon_like_grey.png';

// 获取视频详情
const getVideoDetail = () => {
  getVideoDetailApi(videosId.value).then((res) => {
    let data = res.data;
    coverImageUrl.value = JSON.parse(data.coverImageUrl)[0];
    videoUrl.value = JSON.parse(data.videoUrl)[0];
    nextTick(() => {
      videoDetail.value = data;
    });
  });
};

// 新增视频浏览记录
const tourVideo = () => {
  const params = {
    videoId: videosId.value,
    userId: userInfo.value.id,
  };
  tourVideolApi(params).then(res => {
    getVideoDetail();
  });
}

// 获取推荐视频列表
const recommendVideoList = ref([]);
const getRecommendVideoList = () => {
  const params = {
    recommended: 1,
  };
  getVideoListApi(params).then((res) => {
    res.rows.map((v) => {
      v.coverImageUrl = JSON.parse(v.coverImageUrl)[0];
    });
    recommendVideoList.value = res.rows;
  });
};

const commentList = ref([]);
// 获取评论列表
const getCommentList = () => {
  let params = {
    videosId: videosId.value,
  };
  getCommentListApi(params).then((res) => {
    let data = res.data;
    let length = 0;
    data.filter(item => item.hidden === 0)
    data.map((v) => {
      if (v.avatar) {
        v.avatar = JSON.parse(v.avatar)[0];
      }
      let children = v.children;
      if (children && children.length > 0) {
        children.map((m, n) => {
          if (m.hidden === 1) {
            children.splice(n, 1);
          }
        })
        children.map((m) => {
          if (m.avatar) {
            m.avatar = JSON.parse(m.avatar)[0];
          }
        });
        length += v.children.length;
      }
    });
    length += data.length;
    commentCount.value = length;
    commentList.value = data;
    
    nextTick(() => {
      commentHeight.value = commentContainer.value.offsetHeight + 150;
    });
  });
};

// 点赞
const handleLike = () => {
  if (!userInfo.value.userType) {
    ElMessage.error({
      message: "您当前暂未登录,登录后可点赞视频~",
    })
    return;
  };
  if (isLiked.value) return;
  const params = {
    videoId: videosId.value,
    userId: userInfo.value.id,
  };
  videoLikeApi(params).then((res) => {
    getVideoDetail();
    queryLikeStatus();
  });
  // ElMessage.success("点赞成功");
};

// 查询是否已经点赞
const queryLikeStatus = () => {
  const params = {
    userId: userInfo.value.id,
    videoId: videosId.value,
  };
  queryLikeListApi(params).then(res => {
    if (res.rows?.length > 0) {
      isLiked.value = true;
    }
  })
};

// 跳转详情
const openDetail = (item) => {
  router.replace("/videoCenter/videoDetail?id=" + item.id);
  router.go(0);
};

// 监听小窗口显示
const handleScroll = () => {
  if (scrollContainer.value) {
    let scrollTop = scrollContainer.value.getBoundingClientRect().top;
    if (scrollTop <= 0) {
      isScrolledToTop.value = true;
    } else {
      isScrolledToTop.value = false;
    };
  }
};

// 评论视频
const videoComment = () => {
  if (!userInfo.value.userType) {
    ElMessage.error({
      message: "您当前暂未登录,登录后可发表评论内容~",
    })
    return;
  };
  const params = {
    cuserId: userInfo.value.id,
    content: message.value,
    parentId: 0,
    videosId: videosId.value,
  };
  addCommentsApi(params).then((res) => {
    getCommentList();
  });
};

const commentId = ref("");
const userId = ref("");
const baseComment = ref(true);
const parentId = ref("");
// 回复评论
const handleReply = (item, itm) => {
  if (!userInfo.value.userType) {
    ElMessage.error({
      message: "您当前暂未登录,登录后可发表评论内容~",
    })
    return;
  };
  placeholder.value = `回复 @用户名${item.cuserIdStr}`;
  commentId.value = item.id;
  userId.value = "";
  baseComment.value = true;
  parentId.value = item.id;
  if (itm) {
    placeholder.value = `回复 @用户名${itm.cuserIdStr}`;
    userId.value = itm.cuserId;
    baseComment.value = false;
    parentId.value = itm.parentId;
  }
  showReply.value = true;
  autofocus.value = true;
  setTimeout(() => {
    commentHeight.value = commentContainer.value.offsetHeight + 150;
    replyRef.value[0].focus();
  });
};

// 发送评论
const sendReply = () => {
  placeholder.value = "";
  showReply.value = false;
  const params = {
    cuserId: userInfo.value.id,
    content: reply.value,
    parentId: parentId.value,
    videosId: videosId.value,
    userId: userId.value,
  };
  addCommentsApi(params).then((res) => {
    reply.value = "";
    getCommentList();
  });
  setTimeout(() => {
    commentHeight.value = commentContainer.value.offsetHeight + 150;
  });
};

// 失焦隐藏
const replyBlur = () => {
  if (!reply.value) {
    showReply.value = false;
    autofocus.value = false;
    setTimeout(() => {
      commentHeight.value = commentContainer.value.offsetHeight + 150;
    });
  }
};

onMounted(() => {
  videosId.value = route.query.id;
  if (Cookies.get("user")) {
    userInfo.value = JSON.parse(Cookies.get("user"));
    userInfo.value.avatar = userInfo.value.avatar ? JSON.parse(userInfo.value.avatar)[0] : null;
  }
  
  getCommentList();
  getRecommendVideoList();
  tourVideo();
  queryLikeStatus();
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener("scroll", handleScroll);
  }
});
</script>

<template>
  <div class="page-container" :style="'padding-bottom:' + commentHeight + 'px'">
    <Breadcrumb name="视频详情"></Breadcrumb>
    <div class="page-content">
      <div class="page-main">
        <div class="main-left">
          <div class="main-left-header">
            <div class="header-title">{{ videoDetail.title }}</div>
            <div class="header-detail flexRow aICenter">
              <div>上传时间：{{ videoDetail.createTime }}</div>
            </div>
          </div>
          <div class="holder">
            <video
              :class="isScrolledToTop ? 'mini-video' : ''"
              :src="videoUrl.url"
              controls
              ref="bigVideo"
              :poster="coverImageUrl.url"
              autoplay
            ></video>
          </div>
          <div class="main-left-operate" ref="scrollContainer">
            <div class="operate-content flexRow aICenter jCBetween">
              <div class="operate-content-data flexRow aICenter">
                <div
                  class="flexRow aICenter jCBetween"
                  style="margin-right: 20px"
                >
                  <img
                    class="icon-data"
                    src="@/assets/icon/icon_eye_grey.png"
                    alt=""
                  />
                  <span>{{ videoDetail.views || 0 }}</span>
                </div>
                <div
                  class="flexRow aICenter jCBetween pointer"
                  @click="handleLike"
                >
                  <img
                    class="icon-data"
                    :src="
                      isLiked
                        ? ICON_LIKE_FULL
                        : ICON_LIKE_GREY
                    "
                    alt=""
                  />
                  <span :class="isLiked ? 'active' : ''">{{
                    videoDetail.likes || 0
                  }}</span>
                </div>
              </div>
              <div class="operate-content-send">
                <el-input
                  class="message-ipt"
                  v-model="message"
                  type="text"
                  placeholder="发个评论~"
                ></el-input>
                <div class="send-btn" @click="videoComment">发送</div>
              </div>
            </div>
          </div>
          <div ref="commentContainer" class="comment-content">
            <div class="comment-title flexRow aICenter">
              <div class="cube"></div>
              <div>
                <span class="comment-title-label">评论</span>
                <span class="comment-title-val">{{ commentCount }}</span>
              </div>
            </div>
            <div
              v-if="commentCount === 0 || !commentCount"
              class="comment-none"
            >
              暂无评论
            </div>
            <div v-else class="comment-list">
              <div
                class="comment-list-item"
                v-for="(item, index) in commentList"
                :key="index"
              >
                <div class="user flexRow aICenter">
                  <img v-if="item.avatar?.url" class="user-avatar" :src="item.avatar?.url" />
                  <img v-else class="user-avatar" src="@/assets/icon/avatar-default.png" />
                  <span class="user-name">{{ item.cuserIdStr }}</span>
                </div>
                <div class="comment-detail">
                  <div class="comment-self pointer" @click="handleReply(item)">
                    <div>{{ item.content }}</div>
                    <div class="comment-info">
                      <span class="comment-info-time">{{ item.createTime }}</span>
                      <span>回复</span>
                    </div>
                  </div>
                  <div
                    class="comment-other pointer"
                    @click="handleReply(item, itm)"
                    v-for="(itm, idx) in item.children"
                    :key="idx"
                  >
                    <span class="other-info flexRow aICenter">
                      <img v-if="itm.avatar?.url" class="other-avatar" :src="itm.avatar?.url" />
                      <img v-else class="other-avatar" src="@/assets/icon/avatar-default.png" />
                      <span class="other-name">{{ itm.cuserIdStr }}</span>
                      <template v-if="itm.userId">
                        <span style="color: #999">&nbsp;&nbsp;回复&nbsp;</span>
                        <span class="other-name" style="color: #1890ff">{{
                          itm.userIdStr
                        }}</span>
                      </template>
                    </span>
                    <span class="comment-txt">{{ itm.content }}</span>
                    <div class="comment-info">
                      <span class="comment-info-time">{{
                        itm.createTime
                      }}</span>
                      <span>回复</span>
                    </div>
                  </div>
                  <div
                    class="comment-reply"
                    v-if="showReply && commentId === item.id"
                  >
                    <img
                      v-if="userInfo.avatar"
                      class="reply-avatar"
                      :src="userInfo.avatar.url"
                    />
                    <img
                      v-else
                      class="reply-avatar"
                      src="@/assets/icon/avatar-default.png"
                    />
                    <el-input
                      ref="replyRef"
                      class="reply-ipt"
                      :autofocus="autofocus"
                      type="text"
                      v-model="reply"
                      :placeholder="placeholder"
                      @blur="replyBlur"
                    ></el-input>
                    <div class="reply-btn" @click="sendReply">发送</div>
                  </div>
                </div>
              </div>
              <div
                class="comment-nomore"
                v-if="commentList && commentList.length > 0"
              >
                没有更多了
              </div>
              <div class="comment-nomore" v-else>暂无评论</div>
            </div>
          </div>
        </div>
        <div class="main-right">
          <div class="flexRow aICenter">
            <div class="cube"></div>
            <div class="title">推荐视频</div>
          </div>
          <div class="list">
            <div
              class="list-item flexRow"
              v-for="(item, index) in recommendVideoList"
              :key="index"
              @click="openDetail(item)"
            >
              <img :src="item.coverImageUrl.url" alt="" />
              <div class="item-info">
                <div class="item-title text_line_2">{{ item.title }}</div>
                <div class="list-item-count flexRow">
                  <div class="flexRow aICenter">
                    <img src="@/assets/icon/icon_eye_grey.png" />
                    <span>{{ item.views || 0 }}</span>
                  </div>
                  <div class="flexRow aICenter">
                    <img src="@/assets/icon/icon_like_grey.png" />
                    <span>{{ item.likes || 0 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="isScrolledToTop" class="mini-video" :style="getPopsition()">
      <video
        controls
        width="100%"
        height="100%"
        ref="miniVideo"
        autoplay
        :muted="false"
        @pause="handlePause"
        @play="handlePlay"
      >
        <source :src="videoUrl.url" type="video/mp4" />
      </video>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-input__wrapper) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.play-test {
  position: fixed;
  top: 50%;
  left: 0;
}
.page-container {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 600px;
  // max-width: 1920px;
  background: #f5f5f7;
  min-height: 900px;
  max-height: 2000px;
  font-size: 0;
  .breadcrumb {
    width: 100%;
    margin: 0 auto;
    height: 37px;
    // max-width: 1920px;
    background: #fff;
    display: flex;
    align-items: center;
    .breadcrumb_content {
      width: 1300px;
      margin: 0 auto;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 14px;
      span {
        cursor: pointer;
      }
      img {
        width: 16px;
        height: 16px;
      }
      .arrow {
        width: 6px;
        height: 9px;
        margin: 0 10px;
      }
    }
  }
  .page-content {
    width: 1300px;
    margin: 0 auto;
    padding-top: 20px;
    box-sizing: border-box;
    .page-main {
      width: 100%;
      height: 503px;
      display: flex;
      margin-bottom: 60px;
      .main-left {
        width: 895px;
        height: 100%;
        position: relative;
        .main-left-header {
          width: 100%;
          height: 92px;
          background-color: #fff;
          padding: 20px 20px 0;
          .header-title {
            font-weight: 600;
            font-size: 20px;
            color: #1f1f1f;
            line-height: 23px;
            margin-bottom: 4px;
          }
          .header-detail {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 16px;
            > div {
              margin-right: 20px;
              margin-top: 4px;
            }
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }
        .holder {
          width: 100%;
          height: 100%;
        }
        video {
          width: 100%;
          height: 100%;
        }
        .mini-video {
          position: fixed;
          right: 10px;
          bottom: 10px;
          z-index: 1000; /* 确保视频在最上层显示 */
          width: 360px;
          height: 200px;
          // animation: mini 0.2s linear forwards;
        }
        @keyframes mini {
          0% {
            width: 100%;
            height: 100%;
          }
          100% {
            width: 360px;
            height: 200px;
          }
        }
        .main-left-operate {
          background-color: #fff;
          padding: 17px 20px 0;
          .operate-content {
            width: 100%;
            height: 76px;
            border-bottom: 1px solid #e7e7e7;
            .operate-content-data {
              font-weight: 400;
              font-size: 14px;
              color: #999999;
              line-height: 16px;
              .icon-data {
                width: 24px;
                height: 24px;
                margin-right: 2px;
              }
              .active {
                color: #1890ff;
              }
            }
          }
        }
        .operate-content-send {
          width: 568px;
          height: 42px;
          display: flex;
          justify-content: space-between;
          .message-ipt {
            width: 500px;
            height: 100%;
          }
          .send-btn {
            width: 68px;
            height: 42px;
            background: #2c3c6f;
            border-radius: 0px 2px 2px 0px;
            border: 1px solid #2c3c6f;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            line-height: 42px;
            cursor: pointer;
          }
        }
        .comment-content {
          background-color: #fff;
          padding: 20px 20px 30px;
          max-height: 1000px;
          overflow: hidden;
          .comment-title {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            .comment-title-label {
              font-weight: 600;
              font-size: 18px;
              color: #1f1f1f;
              line-height: 21px;
              margin: 0 10px;
            }
            .comment-title-val {
              font-weight: 400;
              font-size: 16px;
              color: #999999;
              line-height: 19px;
            }
          }
          .comment-list::-webkit-scrollbar {
            width: 0;
            display: none;
          }
          .comment-list {
            overflow: hidden;
            overflow-y: scroll;
            max-height: 900px;
            .comment-list-item {
              border-bottom: 1px solid #f9f9f9;
              padding: 30px 0;
            }
            .comment-list-item:first-child {
              padding-top: 0;
            }
            .comment-nomore {
              text-align: center;
              color: #999;
              font-weight: 400;
              line-height: 21px;
              margin-top: 30px;
            }
            .user {
              font-weight: 600;
              font-size: 14px;
              color: #2c3c6f;
              line-height: 16px;
              .user-avatar {
                width: 36px;
                height: 36px;
                margin-right: 10px;
                border-radius: 50%;
              }
              .user-name {
                font-weight: 600;
                font-size: 14px;
                color: #2c3c6f;
                line-height: 16px;
              }
            }
            .comment-detail {
              margin-top: 8px;
              padding-left: 46px;
              .comment-self {
                font-weight: 400;
                font-size: 15px;
                color: #1f1f1f;
                line-height: 30px;
              }
              .comment-other {
                margin-top: 20px;
                word-break: break-all;
                line-height: 30px;
                .other-info {
                  position: relative;
                  top: 6px;
                  display: inline-flex;
                }
                .comment-txt {
                  margin-left: 20px;
                }
                .other-avatar {
                  width: 24px;
                  height: 24px;
                }
                .other-name {
                  font-weight: 600;
                  font-size: 14px;
                  color: #2c3c6f;
                  line-height: 16px;
                  margin-left: 10px;
                }
                .other-back {
                  font-weight: 400;
                  font-size: 14px;
                  color: #999999;
                  line-height: 16px;
                  margin: 0 10px;
                }
                .other-user {
                  font-weight: 400;
                  font-size: 14px;
                  color: #1890ff;
                  line-height: 16px;
                }
              }
              .comment-reply {
                margin-top: 28px;
                display: flex;
                align-items: center;
                .reply-avatar {
                  width: 36px;
                  height: 36px;
                  margin-right: 10px;
                }
                .reply-ipt {
                  width: 685px;
                  height: 42px;
                }
                .reply-btn {
                  width: 68px;
                  height: 42px;
                  background: #2c3c6f;
                  border-radius: 2px;
                  border: 1px solid #2c3c6f;
                  font-weight: 400;
                  font-size: 14px;
                  color: #ffffff;
                  text-align: center;
                  line-height: 42px;
                  margin-left: 10px;
                  cursor: pointer;
                }
              }
              .comment-info {
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                line-height: 14px;
                margin-top: 4px;
                .comment-info-time {
                  margin-right: 30px;
                }
              }
            }
          }
          .comment-none {
            text-align: center;
            color: #999;
            font-weight: 400;
            line-height: 21px;
          }
        }
      }
      .main-right {
        flex: 1;
        height: 595px;
        background: #fff;
        padding: 24px;
        box-sizing: border-box;
        font-size: 0;
        .title {
          font-weight: 600;
          font-size: 18px;
          color: #1f1f1f;
          line-height: 21px;
          margin-left: 10px;
        }
        .list {
          margin-top: 24px;
          overflow-y: scroll;
          width: 100%;
          height: 510px;
          .list-item {
            padding: 4px;
            margin-bottom: 20px;
            width: 100%;
            cursor: pointer;
            img {
              width: 116px;
              height: 69px;
              margin-right: 10px;
            }
            .item-title {
              font-weight: 600;
              font-size: 14px;
              color: #1f1f1f;
              line-height: 21px;
            }
            .list-item-count {
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              line-height: 14px;
              margin-top: 5px;
              > div {
                margin-right: 10px;
              }
              img {
                width: 16px;
                height: 16px;
                margin-right: 2px;
              }
            }
          }
        }
        .list::-webkit-scrollbar {
          width: 0;
          display: none;
        }
      }
    }
  }
  .mini-video {
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 360px; /* 小窗口的宽度 */
    height: 200px;
    z-index: 1000; /* 确保视频在最上层显示 */
  }
}
</style>
