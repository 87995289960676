<script setup>
import { useRouter, useRoute } from "vue-router";
import { onMounted, ref, watchEffect } from "vue";
import { getMainMenuApi } from "@/api/login.js";
import { removeToken } from "@/utils/auth";
import Cookies from "js-cookie";
import { ElMessage, ElMessageBox } from "element-plus";

const router = useRouter();
const route = useRoute();
const userInfo = ref();
const tabList = ref([]);
const scrollY = ref(0);
const isFixedTab = ref(false);
const header = ref(null);
const tab = ref(null);

// 切换tab
const handleTab = (item) => {
  if (item.meta.title === '直播互动') {
    router.go(0);
    return;
  };
  // window.open("https://www.ovc-talent.com" + item.name, "@光谷人");
  window.open("http://113.57.190.86:16060" + item.name, '@光谷人');
};
const currentRoute = ref("");

const handleScroll = () => {
  if (window.scrollY > header.value.offsetHeight - tab.value.offsetHeight) {
    isFixedTab.value = true;
  } else {
    isFixedTab.value = false;
  }
  scrollY.value = window.scrollY;
}

const showMenu = (el) => {
  if (!el.authType || (el.authType == 'default' || el.authType.indexOf(userInfo.value.userType) != -1 || el.authType.indexOf(userInfo.value.userType) == -1 && el.authNoticeType != 'hide')) {
      return true
  } else {
      return false
  }
}

onMounted(async () => {
  window.addEventListener('scroll', handleScroll);
  let ret = await getMainMenuApi();
  let data = ret.data;
  if (data && data.length > 0) {
    data.map(item => {
      item.isSelect = false;
      if (item.meta.title === '直播互动') {
        item.isSelect = true;
      }
    })
  }
  tabList.value = data;
});

watchEffect(() => {
  currentRoute.value = router.currentRoute.value.fullPath;
  if (Cookies.get("user")) {
    userInfo.value = JSON.parse(Cookies.get("user"));
  } else {
    userInfo.value = null;
  }
});
</script>
<template>
  <header ref="header">
    <img src="@/assets/index/header_bg.png" class="bg" alt="" />
    <div class="tab" ref="tab" :class="isFixedTab ? 'fixed' : 'noFixed'">
      <div class="content-box">
        <template v-for="(item, index) in tabList" :key="index">
          <div class="tab-holder">
            <div 
              class="tab-item" 
              :class="[item.path === '/portarQz' ? 'noBorder pr0' : '', item.path === '/second/Ggyz' ? 'pl0' : '']"
              @click="handleTab(item)"
            >
              <div style="width: 100%; position: relative;">
                <div class="through"></div>
                <div class="active" v-if="item.isSelect"></div>
                {{ item?.meta.title }}
                <div class="bolder-holder">{{ item?.meta.title }}</div>
                <div class="item-children-content" v-if="item.children && item.children.length > 0 && !item.hidden">
                  <template v-for="(itm, idx) in item.children" :key="idx">
                    <div class="children-item" @click="handleTab(itm)" v-show="showMenu(itm)">{{ itm.meta.title }}</div>
                  </template>
                </div>
              </div>
            </div>
            <div class="tab-dot" v-if="item.path === '/portarQz'">·</div>
          </div>
        </template>
      </div>
      <!-- <div class="holder"></div> -->
    </div>
  </header>
</template>

<style scoped lang="scss">
header {
  width: 100%;
  aspect-ratio: 12.8 / 1;
  position: relative;
  margin: 0 auto;
  z-index: 100;
  user-select: none;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .tab {
    position: absolute;
    left: 0;
    width: 100%;
    aspect-ratio: 43.63636 / 1;
    background: rgba(44, 60, 111, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ffffff;
    .holder {
      width: 100%;
      height: 15px;
      background: #fff;
    }
    .content-box {
      // width: 1300px;
      // aspect-ratio: 36.36363 / 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .tab-holder {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tab-item {
      font-size: 16px;
      display: inline-block;
      // height: 20px;
      height: 26px;
      flex-shrink: 0;
      line-height: 26px;
      cursor: pointer;
      position: relative;
      font-family: Source Han Sans CN;
      padding: 0 24px;
      border-right: 1px solid #fff;
      font-weight: 500;
      .bolder-holder {
        display: block;
        content: attr(data-text);
        font-weight: bold;
        visibility: hidden;
        height: 0;
        color: transparent;
        margin-bottom: 0;
        overflow: hidden;
      }
      .item-children-content {
        position: absolute;
        left: -20px;
        top: 35.5px;
        width: 200px;
        background: rgba(44, 60, 111, 0.8);
        display: none;
        padding-top: 18px;
        // margin-top: 10px;
        .children-item {
          line-height: 22.5px;
          height: 22.5px;
          margin-bottom: 10px;
          padding-left: 22px;
          font-size: 16px;
        }
        .children-item:hover {
          background: hsla(0, 0%, 100%, 0.2);
          font-weight: 600;
        }
      }
      .item-children-content:hover {
        display: block;
      }
      @keyframes show {
        from {
          opacity: 0;
          max-height: 0;
        }
        to {
          opacity: 1;
          max-height: 300px;
        }
      }

      @keyframes hidden {
        0% {
          opacity: 1;
          max-height: 300px;
          display: block;
        }
        30% {
          opacity: 0.5;
          max-height: 300px;
          display: block;
        }
        100% {
          opacity: 0;
          max-height: 0;
          display: none;
        }
      }
    }
    .tab-dot {
      font-size: 16px;
      font-weight: bold;
      padding: 0 8px;
    }
    .tab-holder:last-child .tab-item {
      border-right: none;
    }
    .tab-item > div {
      font-weight: 500;
    }
    .tab-holder:hover .tab-item > div {
      font-weight: bold;
    }
    .tab-holder:hover .item-children-content {
      display: block;
      overflow: hidden;
      box-sizing: border-box;
      animation: show 0.5s linear forwards;
    }
    .tab-holder:not(:hover) .item-children-content {
      animation: hidden 1s linear forwards;
      overflow: hidden;
      display: none;
    }
    .tab-holder:hover .through {
      display: inline-flex;
    }
    .item-children-content:hover {
      display: block;
    }
    .through {
      position: absolute;
      display: none;
      width: 100%;
      height: 2.1px;
      background-color: #fff;
      bottom: -5px;
      left: 0;
      animation: line 0.5s forwards linear;
    }
    .active {
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 2.1px;
      // font-weight: 500;
      background-color: #fff;
      bottom: -5px;
      left: 0;
      animation: line 0.5s forwards linear;
    }
    @keyframes line {
      0% {
        width: 0%;
      }

      100% {
        width: 100%;
      }
    }
  }
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
  .noFixed {
    bottom: -2px;
  }
  .noBorder {
    border: none !important;
  }
  .pl0 {
    padding-left: 0 !important;
  }
  .pr0 {
    padding-right: 0 !important;
  }
}
</style>
