<script setup>
import { createLiveApi, getLiveInfoByUserIdApi, updateLiveApi, closeLiveApi } from "../../api/live.js";
import ImageUpload from "../../components/ImageUpload/index.vue";
import { nextTick, reactive } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import Cookies from "js-cookie";
const userInfo = ref({});
const form = reactive({
  imgUrl: "",
  livePictureAddress: "", // 标题图
  livePicAddress: "", // 预告图
  livePhotographAddress: "", // 封面图
  title: "",
  details: "",
  time: "",
  liveTimeStart: "",
  liveTimeEnd: "",
  anchorId: "",
});
const disabled = ref(false);
const liveId = ref('');

const testForm = () => {
  if (!form.livePhotographAddress || form.livePhotographAddress.length === 0) {
    ElMessage.error("请上传直播封面图");
    return false;
  }
  if (!form.livePictureAddress || form.livePictureAddress.length === 0) {
    ElMessage.error("请上传直播标题图");
    return false;
  }
  if (!form.livePicAddress || form.livePicAddress.length === 0) {
    ElMessage.error("请上传直播预告图");
    return false;
  }
  if (!form.title) {
    ElMessage.error("请输入直播名称");
    return false;
  }
  let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
  if (!reg.test(form.title)) {
    ElMessage.error("直播名称格式输入有误");
    return false;
  }
  if (form.title.length > 60) {
    ElMessage.error("请输入60个字符以内的直播名称");
    return false;
  }
  if (!form.details) {
    ElMessage.error("请输入直播简介");
    return false;
  }
  if (form.details.length > 120) {
    ElMessage.error("请输入120个字符以内的直播简介");
    return false;
  }
  if (!form.time) {
    ElMessage.error("请选择直播时间");
    return false;
  }
  const currentTime = new Date().getTime();
  const startTime = new Date(form.time[0]).getTime();
  if (currentTime > startTime) {
    ElMessage.error("开始时间不能小于当前时间");
    return false;
  }
  return true;
};
// 提交
const handleSubmit = () => {
  if (!testForm()) return;
  const params = {
    livePictureAddress: form.livePictureAddress[0].ossId,
    livePicAddress: form.livePicAddress[0].ossId,
    livePhotographAddress: form.livePhotographAddress[0].ossId,
    title: form.title,
    details: form.details,
    liveTimeStart: form.time[0],
    liveTimeEnd: form.time[1],
    anchorId: userInfo.value.id
  };
  createLiveApi(params).then((res) => {
    if (res.code === 200) {
      disabled.value = true;
      getLiveInfoByUserId();
      ElMessage.success("直播创建成功");
    } else {
      ElMessage.error(res.msg);
    };
  });
};

// 关闭直播
const handleClose = () => {
  ElMessageBox.confirm("请问确定要关闭当前直播吗?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    closeLiveApi(liveId.value)
      .then((res) => {
        if (res.code === 200) {
          ElMessage.success("直播已关闭");
          getLiveInfoByUserId();
        }
      })
      .catch((e) => {
        ElMessage({
          type: "error",
          message: e.message,
        });
      });
  });
}

// 获取已创建的直播信息
const isCreateLive = ref(false);
const camIndex = ref('');
const camIndexUrl = ref('');
const getLiveInfoByUserId = () => {
  const id = userInfo.value.id;
  getLiveInfoByUserIdApi(id).then((res) => {
    if (res.code === 200) {
      let data = res.data;
      if (data.camIndex && data.camIndexUrl) {
        disabled.value = true;
        camIndex.value = data.camIndex;
        camIndexUrl.value = data.camIndexUrl.substring(0, data.camIndexUrl.lastIndexOf('/'));
        isCreateLive.value = true;
        form.title = data.title;
        form.livePictureAddress = [{url: data.imgUrl}];
        form.livePicAddress = [{url: data.livePicAddress}];
        form.livePhotographAddress = [{url: data.livePhotographAddress}];
        form.time = [data.liveTimeStart, data.liveTimeEnd];
        form.details = data.details;
        liveId.value = data.id;
      }
    }
  });
};

// 修改直播
const updateLive = () => {
  if (!testForm()) return;
  const params = {
    livePictureAddress: form.livePictureAddress[0].ossId,
    livePicAddress: form.livePicAddress[0].ossId,
    livePhotographAddress: form.livePhotographAddress[0].ossId,
    title: form.title,
    details: form.details,
    liveTimeStart: form.time[0],
    liveTimeEnd: form.time[1],
    anchorId: userInfo.value.id,
    id: liveId.value
  };
  updateLiveApi(params).then(res => {
    if (res.code === 200) {
      disabled.value = true;
      getLiveInfoByUserId();
      ElMessage.success("直播修改成功");
    } else {
      disabled.value = true;
      getLiveInfoByUserId();
      ElMessage.success("直播修改失败");
    }
  })
}

// 取消修改
const cancelEdit = () => {
  disabled.value = true;
  getLiveInfoByUserId();
}

// 开启直播展示弹窗
const show = ref(false);
const openDialog = () => {
  show.value = true;
};

// 复制内容
const copy = async (text) => {
  try {
    const textToCopy = ref(text);
    await navigator.clipboard.writeText(textToCopy.value);
    ElMessage.success("复制成功");
  } catch (err) {
    console.error('复制失败', err);
  }
};

// 跳转下载OBS下载页面
const openDownload = () => {
  window.open("https://obsproject.com/", "_blank");
}

onMounted(() => {
  if (Cookies.get("user")) {
    userInfo.value = JSON.parse(Cookies.get("user"));
  }
  getLiveInfoByUserId();
});
</script>

<template>
  <div class="page-debut">
    <div class="debut-form">
      <el-descriptions title="">
        <el-descriptions-item label="封面图" label-class-name="label-name">
          <div class="cover-upload" style="width: 100%;">
            <ImageUpload
              v-model="form.livePhotographAddress"
              :is-show-tip="false"
              :file-size="2"
              :limit="1"
              :file-type="['png', 'jpg', 'jpeg']"
              :disabled="disabled"
            ></ImageUpload>
            <div class="tips">
              <span class="required">* </span>
              <span>建议上传尺寸 1080 × 690, 图片大小不超过2M。</span>
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="标题图" label-class-name="label-name">
          <div class="cover-upload" style="width: 100%;">
            <ImageUpload
              v-model="form.livePictureAddress"
              :is-show-tip="false"
              :file-size="2"
              :limit="1"
              :file-type="['png', 'jpg', 'jpeg']"
              :disabled="disabled"
            ></ImageUpload>
            <div class="tips">
              <span class="required">* </span>
              <span>建议上传尺寸 1080 × 400, 图片大小不超过2M。</span>
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="预告图" label-class-name="label-name">
          <div class="cover-upload" style="width: 100%;">
            <ImageUpload
              v-model="form.livePicAddress"
              :is-show-tip="false"
              :file-size="2"
              :limit="1"
              :file-type="['png', 'jpg', 'jpeg']"
              :disabled="disabled"
            ></ImageUpload>
            <div class="tips">
              <span class="required">* </span>
              <span>建议上传尺寸 1080 × 607, 图片大小不超过2M。</span>
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="直播名称" label-class-name="label-name label-mb40">
          <el-input
            v-model="form.title"
            type="text"
            placeholder="请输入直播名称"
            :disabled="disabled"
          ></el-input>
          <div class="tips">
            <span class="required">* </span>
            <span>字符仅包含中文、字母、数字，长度不超过60。</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="直播简介" label-class-name="label-name label-mb40">
          <el-input
            v-model="form.details"
            type="textarea"
            placeholder="请输入直播简介"
            :disabled="disabled"
            rows="3"
          ></el-input>
          <div class="tips">
            <span class="required">* </span>
            <span>字符长度不超过120。</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="直播时间" label-class-name="label-name">
          <el-date-picker
            v-model="form.time"
            type="datetimerange"
            start-placeholder="请选择开始日期时间"
            end-placeholder="请选择结束日期时间"
            format="YYYY-MM-DD HH:mm"
            value-format="YYYY-MM-DD HH:mm"
            :disabled="disabled"
          />
        </el-descriptions-item>
        <el-descriptions-item label="" label-class-name="label-name">
          <div style="display: flex;">
            <div class="submit-btn" @click="handleSubmit()" v-if="!isCreateLive">创建直播</div>
            <div class="submit-btn" @click="openDialog" v-if="isCreateLive && disabled">开启直播</div>
            <div class="submit-btn btn-edit" @click="disabled = false" v-if="isCreateLive && disabled">修改直播信息</div>
            <div class="submit-btn btn-edit" @click="handleClose" v-if="isCreateLive && disabled">关闭直播</div>
            <div class="submit-btn btn-edit" @click="updateLive()" v-if="isCreateLive && !disabled">确认修改</div>
            <div class="submit-btn btn-edit" @click="cancelEdit" v-if="isCreateLive && !disabled">取消</div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- <div style="display: flex;">
      <div class="submit-btn" @click="handleSubmit()" v-if="!isCreateLive">创建直播</div>
      <div class="submit-btn" @click="openDialog" v-if="isCreateLive && disabled">开启直播</div>
      <div class="submit-btn btn-edit" @click="disabled = false" v-if="isCreateLive && disabled">修改直播信息</div>
      <div class="submit-btn btn-edit" @click="handleClose" v-if="isCreateLive && disabled">关闭直播</div>
      <div class="submit-btn btn-edit" @click="updateLive()" v-if="isCreateLive && !disabled">确认修改</div>
      <div class="submit-btn btn-edit" @click="cancelEdit" v-if="isCreateLive && !disabled">取消</div>
    </div> -->

    <el-dialog v-model="show" title="提示" width="500" align-center>
      <div class="info">推流码:  <span class="code" @click="copy(camIndex)">{{ camIndex }}</span> <span class="copy" @click="copy(camIndex)">复制</span></div>
      <div class="info">推流地址:  <br><span class="code code-url" @click="copy(camIndexUrl)">{{ camIndexUrl }}</span> <span class="copy" @click="copy(camIndexUrl)">复制</span></div>
      <div class="tips">请根据提供的推流码和推流地址，通过<span class="bold" @click="openDownload">OBS Studio</span>开启直播。</div>
      <div class="tips">如果您尚未安装<span class="bold" @click="openDownload">OBS Studio</span>，可点击<span class="bold" @click="openDownload">下载</span>安装适合您的直播工具。</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="show = false">
            我知道了
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
:deep(.label-name) {
  font-weight: 600;
  font-size: 14px;
  color: #1f1f1f;
  line-height: 17px;
  width: 60px;
}
:deep(.label-mb40) {
  margin-bottom: 36px;
}
:deep(.el-descriptions__body) {
  background: transparent;
}
:deep(.el-descriptions__content) {
  display: inline-flex;
  flex-direction: column;
}
:deep(.el-descriptions__cell) {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
:deep(.el-input__wrapper) {
  width: 500px;
  height: 42px;
}
:deep(.el-textarea__inner) {
  width: 500px;
  min-height: 183px !important;
}
:deep(.el-range-editor.el-input__wrapper) {
  width: 500px;
  height: 42px;
}

.page-debut {
  padding: 40px;
  .cover-upload {
    width: 148px;
    height: 148px;
  }
  .submit-btn {
    width: 168px;
    height: 44px;
    background: #2c3c6f;
    border-radius: 2px;
    border: 1px solid #2c3c6f;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
  }
  .btn-edit {
    margin-left: 30px;
  }
  .info {
    margin-bottom: 20px;
  }
  .code {
    font-weight: bolder;
    color: #1890ff;
    margin-left: 10px;
    cursor: pointer;
  }
  .code-url {
    margin-left: 0;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    text-decoration: underline
  }
  .copy {
    margin-left: 10px;
    font-weight: 500;
    color: #2c3c6f;
    cursor: pointer;
  }
  .tips {
    color: #999;
    margin-bottom: 10px;
  }
  .bold {
    font-weight: bold;
    display: inline-block;
    margin: 0 10px;
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;
  }
}

.tips {
  margin-top: 4px;
  font-size: 14px;
  color: #333;
  .required {
    color: #ff0000;
  }
  .preview {
    margin-left: 10px;
    cursor: pointer;
    text-decoration: underline;
    color: #2c3c6f;
  }
}
</style>
