<script setup>
import { onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import Cookies from "js-cookie";
const router = useRouter();
const route = useRoute();
const navId = ref(1);
const nav = ref([
  {
    name: "我要开播",
    id: 1,
    path: "/personalCenter/debut",
    pathName: 'Debut',
    show: true
  },
  {
    name: "我浏览的直播",
    id: 2,
    path: "/personalCenter/myBrowseLives",
    pathName: 'MyBrowseLives',
    show: true
    
  },
  {
    name: "我浏览的视频",
    id: 3,
    path: "/personalCenter/myBrowseVideos",
    pathName: 'MyBrowseVideos',
    show: true
  },
  {
    name: "我评论的视频",
    id: 4,
    path: "/personalCenter/myCommentedLives",
    pathName: 'MyCommentedLives',
    show: true
  },
  {
    name: "我的消息",
    id: 5,
    path: "/personalCenter/myMessages",
    pathName: 'MyMessages',
    show: true
  },
]);

// 左侧导航栏切换
const handleNav = (item) => {
  navId.value = item.id;
  router.replace(item.path);
};

watch(
  () => route.path,
  (val) => {
    nav.value.map(item => {
      if (item.path === val) {
        navId.value = item.id
      };
    });
  }
)

onMounted(() => {
  const user = JSON.parse(Cookies.get('user'));
  if (user && user.userType !== '2') {
    nav.value[0].show = false;
    router.replace(route.path);
    nav.value.map(item => {
      if (item.path === route.path) {
        navId.value = item.id
      };
    });
  } else {
    let pathName = router.currentRoute.value.name;
    nav.value.map(v => {
      if (v.pathName === pathName) {
        navId.value = v.id;
      };
    });
  };
});
</script>

<template>
  <div class="page-personal">
    <div class="personal-container flexRow">
      <div class="nav">
        <template  v-for="(item, index) in nav" :key="index">
          <div
            class="nav-item"
            :class="navId === item.id ? 'active' : ''"
            @click="handleNav(item)"
            v-if="item.show"
          >
            <span>{{ item.name }}</span>
          </div>
        </template>
      </div>
      <div class="router-view">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-personal {
  width: 100%;
  // max-width: 1920px;
  background-color: #fff;
  min-height: 875px;
  background-image: url("@/assets/index/index_bg_4.png");
  background-size: 100% auto;
  background-position: bottom center;
  background-repeat: no-repeat;
  padding-top: 90px;
  .personal-container {
    width: 1300px;
    margin: 0 auto;
    .nav {
      width: 183px;
      height: 515px;
      flex-shrink: 0;
      background-image: url("@/assets/video/video_nav_bg.png");
      background-size: 100% 100%;
      background-position: center;
      margin-right: 20px;
      padding: 20px 10px 0;
      box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
      border: 1px solid #f6f6f6;
      .nav-item {
        padding-left: 10px;
        line-height: 32px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
        color: #1f1f1f;
        cursor: pointer;
      }
      .active {
        background: linear-gradient(90deg, #55618c 0%, #738ddf 100%);
        color: #fff;
      }
    }
    .router-view {
      flex: 1;
    }
  }
}
</style>
