<script setup>
import { useRouter } from "vue-router";
import Breadcrumb from "../../components/breadcrumb/index.vue";
import { getPlaybackListApi } from "../../api/live.js";
import { ElMessage } from "element-plus";
const router = useRouter();

const pageNum = ref(1);
const keyword = ref('');

// 获取直播回放列表数据
const playbackList = ref([]);
const total = ref(0);
const getPlaybackList = () => {
  const params = {
    pageSize: 16,
    pageNum: pageNum.value,
    search: keyword.value
  };
  getPlaybackListApi(params).then((res) => {
    let data = res.rows;
    if (data && data.length > 0) {
      data.map(v => {
        v.livePictureAddress = v.livePhotographAddress;
      });
    };
    total.value = res.total;
    playbackList.value = data;
  });
};

// 搜索
const handleSearch = () => {
  pageNum.value = 1;
  getPlaybackList();
}

// 跳转详情
const openDetail = (item) => {
  router.push("/playbackDetail?id=" + item.id);
};

// 分页
const handleChange = (e) => {
  pageNum.value = e;
  getPlaybackList();
};

onMounted(() => {
  getPlaybackList();
});
</script>

<template>
  <div class="page-container">
    <Breadcrumb name="回放列表"></Breadcrumb>
    <div class="page-content">
      <div class="search flexRow jCBetween">
        <el-input
          class="search-ipt"
          type="text"
          placeholder="请输入内容"
          clearable
          v-model="keyword"
          @keyup.enter="handleSearch"
        ></el-input>
        <div class="search-btn pointer" @click="handleSearch">搜索</div>
      </div>
      <div class="page-main">
        <div class="item" v-for="(item, index) in playbackList" :key="index" @click="openDetail(item)">
          <img :src="item?.livePictureAddress" />
          <div class="item-content">
            <div class="label text_line_1">{{ item.title }}</div>
            <div class="name">主播：{{ item.anchorName || "-" }}</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          :background="true"
          :page-size="16"
          layout="total, prev, pager, next"
          :total="total"
          @change="handleChange"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-input__wrapper) {
  box-shadow: none;
  border: none;
  border-radius: 2px 0 0 2px;
}
:deep(.el-pager .number) {
  background: #fff !important;
}
:deep(.el-pager .more) {
  background: #fff !important;
}
:deep(.el-pager .is-active) {
  background: #2c3c6f !important;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
.search {
  width: 100%;
  height: 50px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 2px;
  overflow: hidden;
  .search-ipt {
    width: 1100px;
    outline: none;
  }
  .search-btn {
    flex-shrink: 0;
    width: 100px;
    height: 100%;
    background: #2c3c6f;
    border-radius: 0 2px 2px 0;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
  }
}
.page-main {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  .item {
    cursor: pointer;
    padding: 4px 4px 0;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
    img {
      width: 277px;
      height: 164px;
      object-fit: cover;
      object-position: center;
    }
    .item-content {
      padding: 20px 10px;
      width: 277px;
      box-sizing: border-box;
      .label {
        font-weight: 600;
        font-size: 18px;
        color: #1f1f1f;
        line-height: 21px;
      }
      .name {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 16px;
        margin-top: 10px;
      }
    }
  }
  .item:hover {
    animation: scale 0.2s linear forwards;
  }
}
.pagination {
  width: 100%;
  height: 70px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  padding: 0 30px;
}
</style>
