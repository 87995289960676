<script setup>
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
const props = defineProps({
  name: {
    type: String,
    default: '',
  }
})
const router = useRouter();
const route = useRoute();
const currentPath = ref({});
const handleJump = () => {
  window.open('https://www.ovc-talent.com/homePage', '@光谷人')
};
const backHome = () => {
  if (currentPath.value.path) {
    router.push(currentPath.value.path);
  } else {
    router.push("/liveCenter");
  }
};

const refresh = () => {
  router.go(0);
};

onMounted(() => {
  if (route.meta.pTitle) {
    currentPath.value = route.meta;
  };
})
</script>
<template>
  <div class="breadcrumb">
    <div class="breadcrumb_content flexRow aICenter">
      <img class="pointer home" src="@/assets/icon/icon_home.png" @click="handleJump" />
      <img class="arrow" src="@/assets/icon/icon-arrow-right.png" />
      <span @click="backHome">{{ currentPath.pTitle ? currentPath.pTitle : '直播互动' }}</span>
      <img class="arrow" src="@/assets/icon/icon-arrow-right.png" />
      <span @click="refresh">{{ props.name }}</span>
    </div>
  </div>
</template>
<style scoped lang="scss">
.breadcrumb {
  width: 100%;
  height: 37px;
  margin: 0 auto;
  // max-width: 1920px;
  background: #fff;
  .home {
    width: 16px !important;
    height: 16px !important;
  }
  .arrow {
    width: 12px !important;
    height: 12px !important;
    margin: 0 4.5px !important;
  }
}
</style>
