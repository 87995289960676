
import axios from 'axios';
import { ElMessage } from 'element-plus'
import { getToken, getClientKey, removeToken, removeClientKey, removeExpireKey } from "@/utils/auth";
import router from '@/router';
import Cookies from "js-cookie";

const service = axios.create({
  baseURL: import.meta.env.VITE_TINTMCE_BASE_URL,
  timeout: 50000,
  withCredentials: true,
  headers: { 
    'Content-Type': 'application/json'
  }
});

// 请求拦截
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  if (getToken()) {
    config.headers['Authorization'] = 'Bearer ' + getToken();
    config.headers['clientid'] = getClientKey();
  }
  config.headers['Content-Type'] = config.type ? config.type : 'application/json';
  config.headers['Access-Control-Allow-Origin'] = 'http://113.57.190.86:16060/';
  config.headers['Access-Control-Allow-Credentials'] = 'true';
  return config;
}, error => {
  Promise.reject(error);
});

// 响应拦截
service.interceptors.response.use(response => {
  //判断code码
  if (response.data.code === 401) {
    ElMessage.error({ message: '登录信息已过期!' });
    removeClientKey();
    removeToken();
    removeExpireKey();
    Cookies.remove('user');
    router.replace('/liveCenter');
    setTimeout(() => {
      location.reload();
    }, 1000);
    return;
  }
  return response.data;
}, error => {
  if (error && error.response) {
    switch (error.response.status) {
      case 403:
        error.message = '拒绝访问'
        break;
      case 502:
        error.message = '服务器访问错误'
        break;
      default:
        error.message = `连接错误${error.message}`
    }
  } else {
    error.message = '响应超时，请刷新当前页面'
  }

  ElMessage.error({ message: error.message })
  return Promise.reject(error);
});

export default service;