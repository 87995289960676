<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import Header from "@/components/header/index.vue";
import Footer from "@/components/footer/index.vue";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import { userLoginApi, customerLoginApi } from "@/api/login.js";
import {
  getToken,
  setToken,
  setClientKey,
  removeToken,
  removeClientKey,
  setExpireKey,
  getExpireKey,
} from "@/utils/auth.js";
import Cookies from "js-cookie";
import { ElMessage } from "element-plus";

const showPage = ref(false);
const iframeContainer = ref(null);
const userLogin = () => {
  let token = Cookies.get("ssoToken");
  if (!token && !Cookies.get("clientid")) {
    customerLoginApi({ clientId: "e5cd7e4891bf95d1d19206ce24a7b32e" }).then(res => {
      setToken(res.data.access_token);
      setClientKey(res.data.client_id);
      Cookies.set("user", JSON.stringify(res.data.hqUser));
      showPage.value = true;
    })
    return;
  } else if (!token && getToken()) {
    showPage.value = true;
  };
  if (
    getExpireKey() &&
    Math.round(new Date() / 1000) < Number(getExpireKey())
  ) {
    showPage.value = true;
    return;
  };
  if (!token) return;
  const params = {
    token: 'Bearer ' + token,
    clientId: "e5cd7e4891bf95d1d19206ce24a7b32e",
  };
  userLoginApi(params).then((res) => {
    if (res.code === 200) {
      setToken(res.data.access_token);
      setClientKey(res.data.client_id);
      setExpireKey();
      Cookies.set("user", JSON.stringify(res.data.hqUser));
      showPage.value = true;
    } else {
      ElMessage.error("服务异常，请稍后再试!");
      removeToken();
      removeClientKey();
      Cookies.remove("ssoToken");
      showPage.value = false;
    };
  })
  .catch((err) => {
    removeToken();
    removeClientKey();
    Cookies.remove("ssoToken");
    showPage.value = false;
  });
};

const toLogin = () => {
  window.open("http://113.57.190.86:16060/LoginRegister", "_blank");
};

const resizeFn = () => {
  setTimeout(() => {
    document.querySelector("html").style.fontSize = (window.innerWidth / 10) + "px";
  }, 200);
};

onMounted(async () => {
  resizeFn();
  window.addEventListener('resize', (e) => {
    resizeFn();
  });
  userLogin();
});

onUnmounted(() => {
  window.removeEventListener('resize', (res) => {
    resizeFn();
  });
});
</script>

<template>
  <div class="app-container">
    <div ref="iframeContainer"></div>
    <el-config-provider :locale="zhCn">
      <Header></Header>
      <router-view v-if="showPage" :locale="zhCn" />
      <div class="unlogin" v-else>
        <div class="tips">您还未登录哦~， <span class="toLogin" @click="toLogin">去登录</span></div>
      </div>
      <Footer></Footer>
    </el-config-provider>
  </div>
</template>

<style lang="scss" scoped>
  .unlogin {
    width: 100%;
    height: calc(100vh - 301px);
    background: #fff;
    min-height: 470px;
    background-image: url(./assets/index/unlogin.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 400px 400px;
    .tips {
      font-size: 18px;
      font-weight: 500;
      position: relative;
      top: 430px;
      text-align: center;
    }
    .toLogin {
      color: rgba(44,60,111,.8);
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
    }
  }
</style>
