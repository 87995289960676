<script setup></script>
<template>
  <footer>
    <img src="@/assets/index/footer_bg.png" class="bg" alt="footer_bg">
  </footer>
</template>
<style scoped lang="scss">
footer {
  width: 100%;
  position: relative;
  aspect-ratio: 8.94 / 1;
  // background-color: #2B3C6E;
  margin: 0 auto;
  font-size: 0;
  // background-image: url(@/assets/index/footer_bg.png);
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
  .bg {
    width: 100%;
    aspect-ratio: 8.94 / 1;
  }
}
</style>
