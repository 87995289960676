
import request from '@/utils/request'

// 用户登录
export function userLoginApi( data ){
	return request({
		url:'/auth/pcLogin',
		method:"post",
		data
	})
}

// 游客登录
export function customerLoginApi( data ){
	return request({
		url:'/auth/linLogin',
		method:"post",
		data
	})
}

// 获取菜单列表
export function getMainMenuApi( data ){
	return request({
		url:'/auth/getMainMenu',
		method:"get",
		data
	})
}
